import React, { useContext, useEffect } from "react";
import { UserContext } from "./UserProvider";
import UserService from "../services/user.service";
import { useQuery } from "@tanstack/react-query";
import { errorCatch } from "../utils/errorCatch";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

//получает данные пользователя и сохраняет их в state UserContext
const AuthProvider = ({ children }) => {

    const { setUser, user } = useContext(UserContext);

    const res = useQuery({
        queryFn: () => UserService.getProfile(),
        queryKey: ["user"],
    });

    if (res.error) {
        toast.error(errorCatch(res.error));
    }

    console.log("re-render AuthProvider, UserService.getProfile()", res.data);

    useEffect(() => {
        if (res.data) {
            setUser(res.data);
            console.log("re-render AuthProvider , useEffect");
        }
    }, [res.data]);

  return <>{children}</>;
};

export default AuthProvider;
