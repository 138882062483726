import React from "react";
import Router from "./router/Router";

const App = () => {
  return (
      <Router />
  );
};

export default App;
