import Alert from "@mui/material/Alert/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from "@mui/material/Snackbar/Snackbar";
import React from "react";

export default (props) => {

    const {toast, setToast} = props;

    return (
        <Snackbar
            open={toast.open}
            autoHideDuration={toast.closeTime || 2000}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            onClose={() => setToast({...toast, open: false})}
        >
            <Alert
                className="toastAlert"
                severity={toast.severity}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => setToast({...toast, open: false})}
                    >
                        <CloseIcon fontSize="inherit"/>
                    </IconButton>
                }
            >
                {toast.text}
            </Alert>
        </Snackbar>
    )
}