import instance, {$axios} from "../api/axios.api";

const MainService = {

  async sendRequest({name, phone, message}) {
    const { data } = await $axios.post("/main/new-request", {name, phone, message});
    return data;
  },

};
export default MainService;
