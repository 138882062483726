import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import UserProvider from "./UserProvider";
import QueryProvider from "./QueryProvider";
import AuthProvider from "./AuthProvider";
import { StyledEngineProvider } from '@mui/material/styles';

//заворачивает children-компонент в провайдеры
const MainProvider = ({ children }) => {
  return (
      <StyledEngineProvider injectFirst>
        <QueryProvider>
          <BrowserRouter>

            <UserProvider>
              <AuthProvider>
                  {children}
              </AuthProvider>
            </UserProvider>

            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />

          </BrowserRouter>
        </QueryProvider>
      </StyledEngineProvider>
  );
};

export default MainProvider;
