import instance from "../api/axios.api";
import { getTokens } from "../api/tokens.api";

const UserService = {
  async getProfile() {
    const { accessToken } = getTokens();
    if (accessToken) {
      const { data } = await instance.get("/user/profile");
      return data;
    }
    return null;
  },
};

export default UserService;
