import * as React from "react";
const svgTelegram = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={27}
    fill="none"
    {...props}
  >
    <path
      fill="#4092FF"
      d="M19.02 8.081h.019c.187 0 .361.058.504.159l-.003-.002c.104.09.174.218.191.361v.003a2.292 2.292 0 0 1 .021.536V9.13c-.201 2.124-1.076 7.274-1.52 9.65-.189 1.008-.56 1.345-.918 1.377-.779.072-1.37-.515-2.125-1.01-1.182-.775-1.85-1.257-2.996-2.013-1.326-.872-.466-1.354.289-2.137.198-.206 3.632-3.33 3.7-3.613a.276.276 0 0 0-.063-.238.304.304 0 0 0-.166-.047.328.328 0 0 0-.115.021h.002c-.119.026-2.006 1.273-5.662 3.74a2.515 2.515 0 0 1-1.451.538H8.72a9.45 9.45 0 0 1-2.152-.514l.066.022c-.84-.275-1.508-.419-1.451-.883.03-.242.363-.489.999-.742 3.913-1.705 6.522-2.829 7.828-3.371a20.282 20.282 0 0 1 4.867-1.802l.14-.028.001.001ZM13.468 0C6.027.018 0 6.055 0 13.5 0 20.956 6.044 27 13.5 27S27 20.956 27 13.5C27 6.055 20.973.018 13.533 0h-.064Z"
    />
  </svg>
);
export default svgTelegram;
