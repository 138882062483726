import { $axios } from "../api/axios.api";
import { saveTokens, getTokens } from "../api/tokens.api";

const AuthService = {

  async login(login, password) {
    const { data } = await $axios.post("/auth/login", { login, password });
    this.newTokens(data);
    return data.user;
  },

  async register(login, password) {
    const { data } = await $axios.post(`/auth/register`, { login, password });
    this.newTokens(data);
    return data.user;
  },

  async getNewTokens() {
    const { refreshToken } = getTokens();
    const { data } = await $axios("/auth/login/access-Token", { refreshToken });
    this.newTokens(data);
    return data;
  },

  newTokens(data) {
    if (data.tokens.accessToken) {
      saveTokens({
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken,
      });
    }
  },

};

export default AuthService;
