import Cookies from "js-cookie";

export const API_URL = `https://ao-gorizont.ru/api`;

export const getTokens = () => {
	const accessToken = Cookies.get('access-token');
	const refreshToken = Cookies.get('refresh-token');
	return {accessToken, refreshToken}
}

export const saveTokens = ({ accessToken, refreshToken}) => {
	Cookies.set('access-token', accessToken);
	Cookies.set('refresh-token', refreshToken);
}

export const removeToken = () => {
	Cookies.remove('access-token');
	Cookies.remove('refresh-token');
}