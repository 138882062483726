import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import MainProvider from "./providers/MainProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <MainProvider>
            <App />
        </MainProvider>
    </React.StrictMode>
);