import React from "react";
import { Routes, Route } from "react-router-dom";
import Main from "../pages/Main/Main";

//загружает нужный компонент в зависимости от url
const Router = () => {
    return (
        <Routes>
            <Route path="/" element={<Main/>}/>
        </Routes>
        );
    };

export default Router;
