import * as React from "react";
const SvgArrowRight = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={34}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="m24 .197-.98.197v.003l.001.006.004.017a9.122 9.122 0 0 0 .06.25c.042.165.107.4.2.694.189.59.494 1.421.968 2.418.949 1.996 2.574 4.656 5.29 7.372 2.105 2.105 4.177 3.555 5.932 4.543H0v2h35.311c-1.667.974-3.64 2.415-5.768 4.543-2.96 2.959-4.59 5.62-5.484 7.572-.446.975-.707 1.77-.857 2.334a8.82 8.82 0 0 0-.184.853 3.595 3.595 0 0 0-.008.057l-.003.018v.011l.993.112.994.111v.001l.002-.018a6.88 6.88 0 0 1 .138-.629c.123-.46.347-1.15.744-2.018.794-1.735 2.288-4.199 5.08-6.99 2.79-2.791 5.254-4.285 6.99-5.08a12.911 12.911 0 0 1 2.017-.743 6.805 6.805 0 0 1 .605-.134H41v-2h-.389a10.439 10.439 0 0 1-.67-.19 17.47 17.47 0 0 1-2.168-.87c-1.817-.863-4.282-2.363-6.816-4.897-2.534-2.534-4.034-5-4.898-6.816a17.47 17.47 0 0 1-.868-2.168 10.53 10.53 0 0 1-.203-.723l-.007-.03V0L24 .197Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowRight;
