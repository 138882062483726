import * as React from "react";
const svgWhatsapp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={28}
    fill="none"
    {...props}
  >
    <path
      fill="#4092FF"
      fillRule="evenodd"
      d="M18.622 1.006c1.646.679 3.14 1.68 4.394 2.945a13.484 13.484 0 0 1-9.587 23.052h.098a13.41 13.41 0 0 1-6.387-1.63L0 27.183l1.812-6.977A13.483 13.483 0 0 1 13.43 0c1.78-.014 3.546.328 5.193 1.006ZM7.72 23.106a11.09 11.09 0 0 0 5.709 1.577 11.218 11.218 0 0 0 11.2-11.2 11.09 11.09 0 0 0-3.28-7.919A11.2 11.2 0 0 0 3.95 19.428l.253.417-1.123 4.132 4.24-1.106.4.236Zm11.278-7.119.629.306c.04.02.08.038.118.055.276.13.454.213.534.325.079.54.01 1.091-.2 1.595a3.5 3.5 0 0 1-2.283 1.595 4.585 4.585 0 0 1-2.12-.127l-.098-.033c-.473-.157-1.06-.352-1.805-.674a15.078 15.078 0 0 1-5.78-5.074 6.506 6.506 0 0 1-1.36-3.534A3.806 3.806 0 0 1 7.81 7.61c.235-.253.56-.403.906-.416h.725c.02 0 .042 0 .064-.002.188-.009.419-.02.679.582.076.175.183.417.301.681.334.75.75 1.681.75 1.802a.688.688 0 0 1 0 .598c-.084.2-.194.39-.326.562-.181.199-.363.398-.508.58a3.72 3.72 0 0 1-.042.051c-.137.165-.283.342-.103.637a9.513 9.513 0 0 0 1.813 2.338 9.296 9.296 0 0 0 2.7 1.667c.344.254.526.236.725 0l.05-.06c.24-.282.8-.944 1.02-1.263.235-.344.452-.308.76-.18a60.98 60.98 0 0 1 1.673.8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default svgWhatsapp;
